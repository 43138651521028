<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol col="12" class="py-3">
						<MessageForm @apply-form="createMessage" @cancel-form="goToMessageList"></MessageForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { CREATE_MESSAGE } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("messages")
export default {
	name: "CreateMessage",

	components: {
		MessageForm: () => import("../components/MessageForm"),
	},

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		...mapActions({ CREATE_MESSAGE }),

		createMessage(params) {
			this.CREATE_MESSAGE(params)
		},

		goToMessageList() {
			this.$router.push({ name: "MessageList" })
		},
	},
}
</script>

<style lang="scss" scoped></style>
